import Dashboard from '../../../lib/api/Dashboard';


export function routeRequest() {
	return {
		type: 'ROUTE_REQUEST'
	};
}

export function routeSuccess(data) {
	
	return {
		type: 'ROUTE_SUCCESS',
		data: data,
		lastUpdate: Date.now()
	};
}

export function routeError(error) {
	return {
		type: 'ROUTE_ERROR',
		error: error
	};
}

export function updateRoute(data) {

	return {
		type: 'ROUTE_UPDATE',
		data: data,
	};
}

export function updateRouteActivity(data) {

	return {
		type: 'ROUTE_ACTIVITY_UPDATE',
		data: data,
	};
}

export function updateRouteTrips(data) {
	return {
		type: 'ROUTE_TRIPS_UPDATE',
		data: data,
	};
}


/* Async actions */

export function getRoute(id) {

	return function(dispatch, getState) {

		const state = getState();

		if (state.routes.current.loading) {
			return Promise.resolve();
		}
		
		dispatch(routeRequest());

		return Dashboard.getRoute(id).then(
			data => dispatch(routeSuccess(data)),
			error => dispatch(routeError(error))
		);
	}
}


export function getTrips(licenseplate, startdate, enddate) {

	return function(dispatch, getState) {
		return Dashboard.getTrips(licenseplate, { startdate: startdate, enddate: enddate, filter_method: 0, limit: 1000 }).then(
			data => dispatch(updateRouteTrips(data)),
			error => { return { error: error } }
		);
	}
}
