const initialState = {
    
    online: navigator.onLine
};

export default function app(state = initialState, action) {

    switch (action.type) {

        case 'IS_ONLINE':

            var { online } = action;
            
            return Object.assign({}, state, {
                online: online
            });

        default:
            return state;
        
    }
    
}
