import Dashboard from '../../lib/api/Dashboard';

export function userRequest() {
	return {
		type: 'USER_REQUEST'
	};
}

export function userSuccess(jwt, user) {
	return {
		type: 'USER_SUCCESS',
		jwt: jwt,
		user: user,
		lastUpdate: Date.now()
	};
}

export function userError(error) {
	return {
		type: 'USER_ERROR',
		error: error
	};
}

export function logout() {
	return {
		type: 'LOGOUT',
	};
}


/* Async actions */

export function userLogin(identifier, password) {

	return function(dispatch, getState) {

		dispatch(userRequest());
		
		return Dashboard.login(identifier, password).then(
			({jwt, user}) => dispatch(userSuccess(jwt, user)),
			(error) => dispatch(userError(error))
		);
	}
	
}
