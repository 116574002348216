import React, { Component } from 'react';

import { MapProvider }      from './MapProvider';

import MapStyle             from './MapStyle';

import './Maps.css';


class Map extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            map: null
        };

        this.map_ref = React.createRef();
    }

    componentDidMount() {

        const { map } = this.state;
        const { zoom, center, panX, streetViewControl=false, fitBounds=null, onStreetView=null } = this.props;
        
        if (!map) {
            
            const map = new window.google.maps.Map(this.map_ref.current, {
                zoom: zoom,
                minZoom: 7,
                center: {
                    lat: center.latitude,
                    lng: center.longitude
                },
        
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: streetViewControl,
    

                rotateControl: false,
                fullscreenControl: false,
            
                //styles: MapStyle,
                restriction: {
                    latLngBounds: {
                        east: 12,
                        north: 53.8,
                        south: 49.5,
                        west: 0
                    },
                    strictBounds: true
                }
            });

            if (streetViewControl) {
                const streetView = new window.google.maps.StreetViewPanorama(map.getDiv(), {
                    visible: false,
                    enableCloseButton: true,
                    addressControlOptions: {
                        position: window.google.maps.ControlPosition.BOTTOM_CENTER
                    }
                });
                
                map.setStreetView(streetView);
            }

            if (fitBounds !== null) {
                map.fitBounds(fitBounds);
            }

            map.panBy(panX, 0);

            if (onStreetView !== null) {
                const streetview = map.getStreetView()
                window.google.maps.event.addListener(streetview, 'visible_changed', () => {
                    onStreetView(streetview.getVisible());
                });
            }

            this.setState({
                map: map
            });
        }
    }

    componentDidUpdate(prevProps) {
        
        const { map } = this.state;
        const { center, updateCenter } = this.props;
        
        if (updateCenter && center.latitude !== null && center.longitude !== null) {
            map.panTo({
                lat: center.latitude,
                lng: center.longitude
            });
        }
    }

    center = (lat, lng, zoom=null) => {
        const { map } = this.state;

        map.setCenter(new window.google.maps.LatLng(lat, lng));
        if (zoom !== null) {
            map.setZoom(zoom);
        } 
        
    }

    render() {

        const { map } = this.state;
        const { children } = this.props;

        return (
            <MapProvider map={map}>
                <div
                    ref={this.map_ref}
                    className="map"
                >
                    { map ? children : null }
                </div>
            </MapProvider>
        );
    }
}

Map.defaultProps = {
    zoom: 8,
    center: {
        latitude: 52.132633,
        longitude: 5.291266
    },
    panX: 0,
    updateCenter: false,
    fitBounds: null
};

export default Map;
