import React, { Component } from 'react';

import './Tabs.css';

class Tab extends Component {

    render() {
        const { children } = this.props;

        return (
            <div className="tab">
                { children }
            </div>
        );

    }

}

Tab.defaultProps = {
    label: 'Tab'
};

export default Tab;
