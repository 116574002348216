import { createSelector } from 'reselect';

const getActivities = (state) => state.routes.current.data.activities;

export const getSortedActivities = createSelector(
    [getActivities],
    (activities) => {
        if (activities === undefined) { return []; }

        let sorted_activities = activities.filter(({ combined }) => {
            if (combined) { return false; }
            return true;
        });
        
        sorted_activities.sort((a, b) => {

            let a_drive_time = a.status_history[a.status_history.length-1];
            let b_drive_time = b.status_history[b.status_history.length-1];

            if (a_drive_time.status === 'new' && b_drive_time.status !== 'new') {
                return 1;
            } else if (a_drive_time.status !== 'new' && b_drive_time.status === 'new') {
                return -1;
            } else if (a_drive_time.status !== 'new' && b_drive_time.status !== 'new') {
                if (a_drive_time.timestamp < b_drive_time.timestamp) {
                    return -1;
                } else {
                    return 1;
                }
            }

            return (a.sequence > b.sequence) ? 1 : -1;

        });
      
        return sorted_activities;

    }
);