import React, { Component } from 'react';

import { 
    mdiPauseCircleOutline,
    mdiTruck,
    mdiAccount,
    mdiAlarm
}                           from '@mdi/js';

import Icon                 from '@mdi/react';

import { format, differenceInMinutes } from 'date-fns';

import { 
    timeFormat, 
    msToTime,
}                           from '../../../../lib/utils/Utils';

import './Activity.css';

class Activity extends Component {

    renderEta = () => {
        const { data } = this.props;
        const { planned_arival='', ptv } = data;

        let planned_arival_time = '';
        if (planned_arival !== '') {
            const [hours, minutes, seconds] = planned_arival.split(':');

            let new_inutes = String( (Number(minutes) + (Number(seconds) > 30 ? 1 : 0)) );
            new_inutes = (new_inutes.length == 1 ? '0' : '') + new_inutes;

            planned_arival_time = hours +':'+ new_inutes;

        }

        let arival_diff_time = '';
        let arival_diff_time_color = '';
        let arival_diff_time_updated = '';

        if (ptv) {
            const { scheduledTime, estimatedTime, notificationTime, arrivedTime=null } = ptv;

            if (scheduledTime && estimatedTime && notificationTime) {
                const diff_minutes = differenceInMinutes(new Date(scheduledTime), ( arrivedTime ? new Date(arrivedTime) : new Date(estimatedTime)));

                const hours = Math.floor(Math.abs(diff_minutes) / 60);  
                const minutes = Math.abs(diff_minutes) % 60;
    
                arival_diff_time = '('+((diff_minutes < 0) ? '+' : '-');
                arival_diff_time += ("0" + hours).slice(-2) + ":" + ("0" + minutes).slice(-2);
                arival_diff_time += ')';
    
                arival_diff_time_color = ((diff_minutes < 0) ? '#A7001F' : '#a0c876' );
              
                arival_diff_time_updated = format(new Date(notificationTime), 'dd-MM-y H:mm');
                planned_arival_time = timeFormat(new Date(scheduledTime));
            }

        }
 
        if (planned_arival_time === '') { return null }

        return (
            <div className="eta tooltip">
                <Icon path={mdiAlarm} size={'1.2rem'} color={'#57004e'} /> <span>{ planned_arival_time }</span> <span style={{color: arival_diff_time_color, marginLeft: 5}}>{ arival_diff_time }</span>

                {
                    arival_diff_time_updated !== ''
                    ?
                    <span className="tooltiptext">Geupdate<br/>{ arival_diff_time_updated }</span>
                    :
                    <span className="tooltiptext">Nog niet geupdate<br/></span>
                    
                }
            </div>
        )
      
    }

    render() {
        const { data, next_activity, focusMarker, activityStops } = this.props;
        const { activity_status, customer_name, status_history, activity_id, active=true, stops, expected_arival, sequence, gpsDeparturetime, gpsArrivaltime } = data;

        let next_status = '';

        if (next_activity) {
            next_status = next_activity.activity_status;
        }
        
        let status_arrived = status_history.find(({ status }) => {
            return (status === 'arrived')
        });

        let status_done = status_history.find(({ status }) => {
            return (status === 'done')
        });

        const max_duration = 30 * 60 * 1000;

        let expected_class = '';
        if (status_arrived && expected_arival !== '') {
            expected_class = (checkExpectedArival(status_arrived.timestamp, expected_arival) ? ' not_expected' : ' expected')
        }

        let departuretime = null;
        if (gpsDeparturetime) {
            const [ hours, minutes ] = gpsDeparturetime.split(':');
            departuretime = hours + ':' + minutes;
        }

        let arrivaltime = null;
        if (gpsArrivaltime) {
            const [ hours, minutes ] = gpsArrivaltime.split(':');
            arrivaltime = hours + ':' + minutes;
        }

        // Only show closest stop
        let gps_stop = null;
        if (activityStops) {
            activityStops.forEach((stop) => {
                if (gps_stop === null || gps_stop.distance > stop.distance) {
                    gps_stop = stop;
                } 
            });
        }

        let stop_expected_class = '';
        if (gps_stop && gps_stop.timestamp && expected_arival !== '') {
            stop_expected_class = (checkExpectedArival(gps_stop.timestamp, expected_arival) ? ' not_expected' : ' expected')
        }

        return (
            <div className={"activity " + (active ? 'active_true' : 'active_false')}>

                <div className={"progress"}>
                    <div className={"dot " + activity_status}>
                        {
                            (active && (activity_status === 'driving_paused' || activity_status === 'arrived_paused'))
                            &&
                            <div className={"icon"}><Icon path={mdiPauseCircleOutline} size={'1.3rem'} color={'#c60000'}/></div>
                        }
                    </div>
                    <div className={"line " + next_status}></div>
                </div>

                <div className="info">
                    <div className="name" onClick={() => { focusMarker(activity_id) }}><span className='number'>{(sequence && sequence !== 999 ? sequence + '. ' : '')}</span>{ customer_name }</div>
                    <div className="arival">
                        <div>{ expected_arival }</div> 

                        {
                            this.renderEta()
                        }

                    </div>

                    {
                        gps_stop
                        &&
                        <div className="activity_times">
                            <div><Icon path={mdiTruck} size={'1.2rem'} color={'#57004e'}/> <span className="label">Aankomst:</span> <span className={"value" + stop_expected_class}>{ timeFormat(new Date(gps_stop.updatedTime)) }</span></div>
                            <div><span className="label">Vertrek:</span> <span className="value">{ timeFormat(new Date(gps_stop.updatedTime+gps_stop.duration)) }</span></div>
                            <div><span className="label">Duur:</span> <span className={"value" + (gps_stop.duration > max_duration ? ' tolong' : '')}>{ msToTime(gps_stop.duration) }</span></div>
                        </div>
                    }

                    {
                        departuretime
                        &&
                        <div className="activity_times">
                            <div><Icon path={mdiTruck} size={'1.2rem'} color={'#57004e'}/> <span className="label">Vertrek:</span> <span className={"value"}>{ departuretime }</span></div>
                        </div>
                    }

                    {
                        arrivaltime
                        &&
                        <div className="activity_times">
                            <div><Icon path={mdiTruck} size={'1.2rem'} color={'#57004e'}/> <span className="label">Aankomst:</span> <span className={"value"}>{ arrivaltime }</span></div>
                        </div>
                    }

                    <div className="activity_times">
                        {
                            status_arrived
                            &&
                            <div><Icon path={mdiAccount} size={'1.2rem'} color={'#57004e'}/> <span className="label">Aankomst:</span> <span className={"value" + expected_class}>{ timeFormat(new Date(status_arrived.timestamp)) }</span></div>
                        }

                        {
                            status_done && status_arrived
                            &&
                            <>
                                <div><span className="label">Vertrek:</span> <span className="value">{ timeFormat(new Date(status_done.timestamp)) }</span></div>
                                {
                                    status_arrived
                                    &&
                                    <div><span className="label">Duur:</span> <span className={"value" + ((status_done.timestamp-status_arrived.timestamp) > max_duration ? ' tolong' : '')}>{ msToTime(status_done.timestamp-status_arrived.timestamp) }</span></div>
                                }
                            </>
                        }
                    </div>
        
                </div>
            </div>
        );
    }
}

Activity.defaultProps = {
    data: {}
}

export default Activity;

const checkExpectedArival = (arival, expected_arival='') => {
    const arival_array = expected_arival.split(' ');

    let result = true;

    if (expected_arival.includes('Tussen')) {

        const [start_hours, start_min] = arival_array[1].split(':');
        const [end_hours, end_min] = arival_array[3].split(':');

        const start = new Date(arival).setHours(start_hours, start_min, 0);
        const end = new Date(arival).setHours(end_hours, end_min, 0);
        
        result = !(arival > start && arival < end);

    } else if (expected_arival.includes('Niet tussen')) {

        const [start_hours, start_min] = arival_array[2].split(':');
        const [end_hours, end_min] = arival_array[4].split(':');

        const start = new Date(arival).setHours(start_hours, start_min, 0);
        const end = new Date(arival).setHours(end_hours, end_min, 0);

        result = !(arival < start || arival > end);

    } else if (expected_arival.includes('Na')) {

        const [start_hours, start_min] = arival_array[1].split(':');
        const start = new Date(arival).setHours(start_hours, start_min, 0);

        result = !(arival > start);
   
    } else if (expected_arival.includes('Vóór')) {

        const [start_hours, start_min] = arival_array[1].split(':');
        const start = new Date(arival).setHours(start_hours, start_min, 0);

        result = !(arival > start);
    
    }

    return result;
}
