import React, { PureComponent } from 'react';

import { MapContext }       from './MapProvider';

class Circle extends PureComponent {

    constructor(props, context) {
        super(props, context);   
        
        this.circle = null;
    }

    componentDidMount() {

        const { center, radius, strokeColor, strokeOpacity, strokeWeight, fillColor, fillOpacity } = this.props;

        this.circle = new window.google.maps.Circle({
            map: this.context.map,
            strokeColor: strokeColor,
            strokeOpacity: strokeOpacity,
            strokeWeight: strokeWeight,
            fillColor: fillColor,
            fillOpacity: fillOpacity,
            center: {
                lat: center.latitude,
                lng: center.longitude
            },
            radius: radius
        });
    }

    componentWillUnmount() {
        this.circle.setMap(null);
    }

    render() {
        return null;
    }
}

Circle.contextType = MapContext;

Circle.defaultProps = {
    strokeColor: '#FF0000',
    strokeOpacity: 1,
    strokeWeight: 0,
    fillColor: '#FF0000',
    fillOpacity: 0.5,
};

export default Circle;
