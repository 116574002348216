import React, { Component } from 'react';

import {
    Switch,
    Route
}                           from 'react-router-dom';

import List                 from './list/List';
import Details              from './details/Details';
import Routes               from '../routes/Routes';


class Branches extends Component {

    render() {

        const { match: { path } } = this.props;

        return (
            <Switch>
                <Route path={path + '/'} exact component={List} />
                <Route path={path + '/:branche_id'} exact component={Details} />
                <Route path={path + '/:branche_id/routes'} component={Routes} />
            </Switch> 
        );
    }
}

export default Branches;
