const initialState = {
    
    data: {},
    list: [],

    error: null,
    loading: false,
    lastUpdate: null
};

export default function brancheList(state = initialState, action) {

    switch (action.type) {

        case 'BRANCHES_REQUEST':

            return Object.assign({}, state, {
                error: null,
                loading: true
            });

        case 'BRANCHES_SUCCESS':

            var { lastUpdate } = action;

            var { data=[] } = action.data || {};
            
            var item_data = {};
            var item_list = [];

            data.forEach((item) => {
                item_data[item.documentId] = item;
                item_list.push(item.documentId);
            });

            return Object.assign({}, state, {
                data: item_data,
                list: item_list,
                error: null,
                loading: false,
                lastUpdate: lastUpdate
            });

        case 'BRANCHES_ERROR':

            var { error } = action;

            return Object.assign({}, state, {
                error: error,
                loading: false
            });

        default:
            return state;
        
    }
    
}
