
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import ReactGA from "react-ga4";

const GoogleAnalytics = () => {
    const location = useLocation();

    useEffect(() => {
        if (process.env.REACT_APP_GA !== undefined) {
            ReactGA.initialize(process.env.REACT_APP_GA);
        }
    }, []);

    useEffect(() => {
        if (process.env.REACT_APP_GA !== undefined) {
            ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
        }
    }, [location]);

    return null;
}

export default GoogleAnalytics;