import WebService           from '../webservice/WebService';

class Crossyn {

    constructor() {

        const token = 'YzIxMzBjMWEtY2QzYy00MmU3LWE4M2EtOWE4NDAyOTliNmUx~AAAAIAAAAkWAsqzlnkIcvbp1pNXXkva3Qx3Kbx1yNs6RuHz051pPinjr71-hpKRvZyN_GBtXume-Wu4eHX_IfSnraj5qPR9bwp9h1mxn99AbNfEOYgHAOYu9AlZ464Ug5-SOD4ZtAxBS5TCPLYQFUtQIgEBxvQLIuyZqnUzgrn9CAsGjneTHJDYHXOT_RKIJW6TVZ01hpUhlb3NTLyPq2y6oX79972MqH-wp12ydawMgbDeyh3anQNKXeVlqvWHcshk-Wqn3515qwZSmK-L0hdD6PGjwlHTrhyHn0iqFOqA38h6ycC1sZQGFEL7MxASnnXkqP0pESsQTOlm8SWZ8R-KFse-6ynnDtF3S7x9yug6XsMFJArRqUPkvCLk1Vcsios1Htq80DEkyPtYYc1ewH8jwiw6NtDkp4kP5mUZj_unLUf7xqBnkHDx2hCy2iA6n4B4o83CYPc4R2u9Fdn1br-ythXsjPhCoNOKp3QEhR3oNtmJZfurQg7jPaoVlF9Gq8ZJXZqHG3XNBkFJDdSoHlr6IUdoNBAOGJi1qm9hznHGQ5FHNjJ8M88pSCL33bx8vzcwomfeLJbPz4esRKJFEU7kpXkQfKnBmZeFL-7LCKN8eaVEDLcOauwurd2Kqcq_zvJfivEttu42msWIUPV8_J76xB9scqktvYC00QVocXQpaIKL8ghcQpFL1Xc4vi9EwV3NY4saNyy5qFMj9DzU2534-l03B74Y_bZtUqFGg503iploXACI_sFQmHdusctOFclILHeEcou1eIWmr37qvgofnOwsZENOPej3v5VNV_dPq2jT-ZIvEH0pdQ1Usv_TXh5tef7EzQuzwMXCqj7gAAmcOOC8kOHDKfeUi-NciLHBHwdBholgFdSpKCBLYNHsg7l0ZnC8DyZkfOSMBzfqq9xUv6PF15cGLUSq9pKjA1dE2wcWMReRxvWXo7lWOnM8q8nbAEQMYpL06TIGA2oiwrTro0R5Tc1HCZE0Lu29pTnf0i7oN1iAkdI_tUSV6H7ZMk61A_N6Mq3HO_Hj_eWcp';
        
        this.service_id = '48eece29c2-81b324be-0f79-46a0-b20e-61f48585a521';
        this.driver_id = 'aaee5cd5dc-9b675f04-db56-41db-aab3-aefdcb8ca11c';

        this.webservice = new WebService({
            url: 'https://api.crossyn.com/crossyn-api/2.0/api/service-provider/services', 
            headers: {
                Authorization: ' Bearer ' + token           
            }
        });


 
    }

    getServiceInfo = () => {
        return this.webservice.get('/' + this.service_id);
    }

    getDriverInfo = () => {
        return this.webservice.get('/' + this.service_id+'/drivers/'+this.driver_id);
    }

    getVehicles = () => {
        return this.webservice.get('/' + this.service_id+'/drivers/'+this.driver_id+'/vehicles');
    }

    getVehiclePosition = (vehicle_id) => {
        return this.webservice.get('/' + this.service_id+'/vehicles/'+vehicle_id+'/position');
    }

    getAllPositions = () => {
        return this.webservice.get('/' + this.service_id+'/vehicles/position');
    }

    getTrips = (vehicle_id, params) => {
        return this.webservice.get('/' + this.service_id+'/drivers/'+this.driver_id+'/vehicles/'+vehicle_id+'/trips', params);
    }

}

export default new Crossyn();