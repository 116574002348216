import React, { Component } from 'react';

import {
    mdiAlertCircleOutline  
}                           from '@mdi/js';

import Icon                 from '@mdi/react';

import './State.css';


class ErrorState extends Component {

    render() {

        const { label, message } = this.props;

        return (
            <div className="state-container">
                
                <div className="state-content">
                    <Icon path={mdiAlertCircleOutline} size={'6rem'} color="#CCC" />
                    <div className="state-text">
                        <div>{ label }</div>
                        <div>{ message }</div>
                    </div>
                </div>

            </div>
        );
    }
}

ErrorState.defaultProps = {
    label: '',
    message: ''
}

export default ErrorState;
