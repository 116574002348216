import React, { Component } from 'react';

import { useState, useMemo } from 'react';

import Marker from '../../../../components/maps/Marker';
import Polyline from '../../../../components/maps/Polyline';

import './GpsgateCoordinates.css';


const GpsgateCoordinates = ({ gpsgate_stream_coordinates = [] }) => {
    const [ show, setShow ] = useState(false);

    const normalPath = useMemo(() => {

        return gpsgate_stream_coordinates.reduce((normalPath, { latitude, longitude }) => {  
            normalPath.push({ lat: latitude, lng: longitude });
            return normalPath;
        }, []);

    }, [ gpsgate_stream_coordinates ])

    return (
        <>
            <button className={"crossyn-data" + (show ? ' crossyn-data-show' : '')} onClick={() => { setShow(!show) }}>GPS data</button>

            {
                show && normalPath
                &&
                <Polyline 
                    path={normalPath} 
                    strokeOpacity={1} 
                    strokeColor={'red'}
                    strokeWeight={3}
                    icons={[
                        {
                            icon: {
                                path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                fillColor: 'red',
                                fillOpacity: 0.5,
                                scale: 1.5,
                            },
                            offset: "25%"
                        },
                        {
                            icon: {
                                path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                fillColor: 'red',
                                fillOpacity: 0.5,
                                scale: 1.5,
                            },
                            offset: "50%"
                        },
                        {
                            icon: {
                                path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                fillColor: 'red',
                                fillOpacity: 0.5,
                                scale: 1.5,
                            },
                            offset: "75%"
                        }
                    ]}
                />
             
            }
            
            {
      
                show
                &&
                gpsgate_stream_coordinates.map((coord, index) => {
                    const { latitude, longitude, duration, speed, positionUpdated } = coord || {};
                   
                    if (duration === 0) {
                        //return null; 
                    }

                    return (
                        <Marker
                            key={'c2'+positionUpdated+'-'+latitude+'-'+longitude + '-'+ index}
                            icon={{ fillColor: (duration === 0 ? '#29235c' : 'red'), scale: 1.2 }} //(duration < 60000 ? 0.6 : 1.4)
                            position={ {
                                latitude: latitude,
                                longitude: longitude
                            }}
                            zIndex={9999}
                            hideZoomLevel={14}
                            infoWindowContent={
                                <div className='infowindow'>
                                    <span style={{fontWeight:'bold'}}>stoped:</span> { positionUpdated } <br/>
                                    <span style={{fontWeight:'bold'}}>duration:</span> { (duration/1000/60).toFixed(2) } Minutes<br/>
                                    <span style={{fontWeight:'bold'}}>speed:</span> { speed } <br/>
                                </div>
                            }
                        />
                    );
                })
            

            }

        </>
    );
}

export default GpsgateCoordinates;