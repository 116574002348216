import React, { Component } from 'react';

import Marker               from '../Marker';

class BranchesLayer extends Component {

    render() {
        const { branches } = this.props;

        return Object.keys(branches).map((key) => {
            const { id, latitude, longitude, color, name } = branches[key];

            return (
                <Marker
                    key={id}
                    icon={{
                        path: 'M14.4 6L14 4H5v17h2v-7h5.6l.4 2h7V6z',
                        fillColor: color,
                    }}
                    position={{
                        latitude: latitude,
                        longitude: longitude
                    }}
                    infoWindowContent={(
                        <div className='infowindow'><span className='name'>{ name }</span></div>
                    )}
                />
            );
     
        });
    }

}

BranchesLayer.defaultProps = {
    route_id: '',
    onClick: () => {}
};

export default BranchesLayer;
