import React, { Component } from 'react';

import { 
    Route, 
    Redirect
} 							from 'react-router-dom';

import { connect }          from 'react-redux';

class AuthRoute extends Component {

    render () {
        const {component, authenticated, ...rest_props} = this.props;

        return authenticated ? (

            <Route {...rest_props} render={(routeProps) => {
                const allprops = {...rest_props.props, ...routeProps};
                return React.createElement(component, allprops);
            }}/>

        ) : (
            <Redirect to={{
                pathname: '/login'
            }}/>
        );
    }
}

function mapStateToProps(state) {	
    const { user: { authenticated } } = state;

	return {
		authenticated: authenticated
	};
}

export default connect(mapStateToProps)(AuthRoute);