import Dashboard from '../../../lib/api/Dashboard';


export function branchesRequest() {
	return {
		type: 'BRANCHES_REQUEST'
	};
}

export function branchesSuccess(data) {
	return {
		type: 'BRANCHES_SUCCESS',
		data: data,
		lastUpdate: Date.now()
	};
}

export function branchesError(error) {
	return {
		type: 'BRANCHES_ERROR',
		error: error
	};
}


/* Async actions */

export function getBranches() {

	return function(dispatch, getState) {

		const state = getState();

		if (state.branches.all.loading) {
			return Promise.resolve();
		}
		
		dispatch(branchesRequest());

		return Dashboard.getBranches().then(
			data => dispatch(branchesSuccess(data)),
			error => dispatch(branchesError(error))
		);
	}
}
