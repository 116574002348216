import React, { Component } from 'react';

import Header               from '../header/Header';

import './Page.css';

class Page extends Component {

	render() {
        const { header, onBack, children } = this.props;

		return (
            <div className={"page" + (header && " page_header") } >
                { header && <Header onBack={onBack} /> }
                { children }
            </div>
		);
	}
}

Page.defaultProps = {
    header: true,
    onBack: null
}

export default Page;