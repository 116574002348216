import React from 'react';

import { useMemo, useEffect } from 'react';

import Dashboard from '../../../../lib/api/Dashboard';

import Polyline from '../../../../components/maps/Polyline';

import './GpsgateCoordinates.css';

const GpsgatePath = ({ route_id, path = [] }) => {

    useEffect(() => {
        Dashboard.getRoutePath(route_id);
    }, [ route_id ])

    const normalPath = useMemo(() => {
        if (!path) return [];

        return path.reduce((normalPath, { location }) => {  
            normalPath.push({ lat: location.latitude, lng: location.longitude });
    
            return normalPath;
        }, []);
    }, [ path ])

    return (
        <>

            {
                path
                &&
                <Polyline 
                    path={normalPath} 
                    strokeOpacity={1} 
                    strokeColor={'#57004e'}
                    strokeWeight={3}
                    icons={[
                        {
                            icon: {
                                path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                fillColor: '#57004e',
                                fillOpacity: 0.5,
                                scale: 1.5,
                            },
                            offset: "25%"
                        },
                        {
                            icon: {
                                path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                fillColor: '#57004e',
                                fillOpacity: 0.5,
                                scale: 1.5,
                            },
                            offset: "50%"
                        },
                        {
                            icon: {
                                path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                fillColor: '#57004e',
                                fillOpacity: 0.5,
                                scale: 1.5,
                            },
                            offset: "75%"
                        }
                    ]}
                />
             
            }
        </>
    )

}

export default GpsgatePath;
