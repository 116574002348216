import React, { Component } from 'react';


import './Tabs.css';

class Tabs extends Component {

    constructor(props) {
        super(props);

        this.state = {
            active: 0
        }
    }

    onClick = (index) => {
        this.setState({active: index});
    }

    render() {
        const { children } = this.props;
        const { active } = this.state;

        return (
            <div className="tabs">
                <div className="tab-list">
                {
                    children.map(({ props }, index) => {
                        const { label } = props;
                        return <div className={"tab-list-item" + (index === active ? ' active' : '')} key={'tli-'+index} onClick={this.onClick.bind(this, index)}>{ label }</div>
                    })
                }
                </div>
                <div className="tab-content">
                {
                    children[active]
                    &&
                    children[active].props.children
                }
                </div>
            </div>
        );

    }

}

Tabs.defaultProps = {

};

export default Tabs;
