import React, { Component } from 'react';

import {
    Switch,
    Route
}                           from 'react-router-dom';

import List                 from './list/List';
import Details              from './details/Details';


class Routes extends Component {

    render() {

        const { match: { path } } = this.props;

        return (
            <Switch>
                <Route path={path + '/'} exact component={List} />
                <Route path={path + '/:route_id'} component={Details} />
            </Switch> 
        );
    }
}

export default Routes;
