import React, { PureComponent } from 'react';

import './Activityindicator.css';

class Activityindicator extends PureComponent {

	render() {

		return (
            <div className="loader">
   
            </div>
		);
	}
}


export default Activityindicator;