import Dashboard from '../../../lib/api/Dashboard';


export function brancheRequest() {
	return {
		type: 'BRANCHE_REQUEST'
	};
}

export function brancheSuccess(data) {
	return {
		type: 'BRANCHE_SUCCESS',
		data: data,
		lastUpdate: Date.now()
	};
}

export function brancheError(error) {
	return {
		type: 'BRANCHE_ERROR',
		error: error
	};
}


/* Async actions */

export function getBranche(id) {

	return function(dispatch, getState) {

		const state = getState();

		if (state.branches.current.loading) {
			return Promise.resolve();
		}
		
		dispatch(brancheRequest());

		return Dashboard.getBranche(id).then(
			data => dispatch(brancheSuccess(data)),
			error => dispatch(brancheError(error))
		);
	}
}
