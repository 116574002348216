import React, { Component } from 'react';

import Marker               from '../Marker';

class Truck extends Component {

    render() {
        const { position, rotation, color, infoWindowContent, type } = this.props;

        let truck_icon = `M33.5,7.8C33.5,7.8,33.5,7.8,33.5,7.8l-3.1-0.6V5.4c0-1-0.9-2-2.6-2.6c-1-0.4-1.9-0.9-2.7-1.5
        c-1.6-0.9-3.4-1.3-5.2-1.2h-5.3c-2-0.1-4,0.4-5.8,1.3c-1,0.7-2,1.2-3,1.7C4.4,3.6,3.5,4.5,3.5,5.3v1.8L0.4,7.7c0,0,0,0,0,0
        C0.1,7.8,0,8,0,8.3L0.2,9c0.1,0.3,0.3,0.4,0.6,0.3l2.7-0.5v13.7c0,0.1,0.1,0.2,0.3,0.3C4,22.9,4.3,23,4.6,23c0.3,0,0.6,0,0.8,0v1.3
        H4.1c-0.4,0-0.8,0.3-0.8,0.8c0,0,0,0,0,0v45.6c0,0.4,0.4,0.8,0.8,0.8c0,0,0,0,0,0h25.2c0.4,0,0.8-0.3,0.8-0.8c0,0,0,0,0,0V25.2
        c0-0.4-0.4-0.8-0.8-0.8c0,0,0,0,0,0h-0.8V23c0.3,0,0.6,0,0.8,0c0.6,0,1.1-0.2,1.1-0.4V8.8l2.7,0.5c0.3,0.1,0.5-0.1,0.6-0.3l0.2-0.7
        C33.9,8.1,33.8,7.8,33.5,7.8z M28.1,12c-7.4-0.9-14.8-0.9-22.2,0C5.4,12,5,11.8,5,11.3C5,10.2,5.1,9.1,5.1,8c0.1-0.7,0.4-1.4,0.8-2
        C13.2,5.3,20.6,5.3,28,6c0.4,0.6,0.7,1.2,0.8,1.9c0,1.1,0.1,2.3,0.2,3.4C29,11.8,28.6,12,28.1,12z`;

        if (type === 'large') {
            truck_icon = `M33.51,7.72l-3-.62V5.37c0-1-.94-2-2.62-2.63a12,12,0,0,1-2.73-1.53A9.25,9.25,0,0,0,19.93,0H14.66A11.15,11.15,0,0,0,8.87,1.31,19.13,19.13,0,0,1,5.85,3c-1.49.63-2.39,1.45-2.39,2.34V7.1L.39,7.72A.47.47,0,0,0,0,8.27L.17,9a.5.5,0,0,0,.6.36l2.69-.54V22.54c0,.12.13.22.33.29A2.58,2.58,0,0,0,4.6,23l.83.05v1.39H4.11a.8.8,0,0,0-.81.79v58.6a.8.8,0,0,0,.81.79H29.3a.8.8,0,0,0,.81-.79V25.18a.8.8,0,0,0-.81-.79h-.81V23l.83-.05c.63,0,1.14-.18,1.14-.41V8.79l2.68.54A.49.49,0,0,0,33.73,9l.16-.7A.46.46,0,0,0,33.51,7.72ZM28.08,12A96.09,96.09,0,0,0,5.85,12c-.5.06-.91-.23-.89-.65C5,10.21,5.07,9.09,5.13,8A4.73,4.73,0,0,1,5.9,6.05,113.53,113.53,0,0,1,28,6.05,4.75,4.75,0,0,1,28.8,8c.05,1.13.11,2.25.16,3.38C29,11.76,28.58,12.05,28.08,12Z`;
        }

        return <Marker
            icon={{
                path: truck_icon,
                fillColor: color,
                scale: 0.4, // 1 = 36x73
                anchor: {x: 17, y: 36} // 24x24
            }}
            position={ position }
            rotation={ rotation }
            infoWindowContent={ infoWindowContent }
        />
    }
}

Truck.defaultProps = {
    position: {
        latitude: 0,
        longitude: 0
    },
    rotation: 0,
    color: '#57004e',
    infoWindowContent: null,
    type: 'normal'
};

export default Truck;
