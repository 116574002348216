import React, { Component } from 'react';

import { connect }          from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import { 
    mdiAccount,
    mdiLogoutVariant,
    mdiFullscreen,
    mdiFullscreenExit,
    mdiArrowLeft,
    mdiMagnify
}                           from '@mdi/js';

import Icon                 from '@mdi/react';

import { logout }           from '../../redux/user/actions';

import Dashboard            from '../../lib/api/Dashboard';

import './Header.css';

class Header extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user_menu: false
        }

    }

    openMenu = (event) => {
        this.setState({user_menu: true});
        event.preventDefault();
    }

    closeMenu = (event) => {
        this.setState({user_menu: false});
        event.preventDefault();
    }

    logout = (event) => {
        const { dispatch } = this.props;
        
        Dashboard.removeToken();

        dispatch(logout());
        event.preventDefault();
    }

    toggleFullscreen = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen(); 
            }
        }
    }

    onSearch = () => {
        const { history } = this.props;
        history.push('/zoeken');
    }

    renderUser = () => {
        const { user: { name, branche } } = this.props;
        const { user_menu } = this.state;
  
        return (
            <React.Fragment>
                <button className="icon search" onClick={this.onSearch}>
                    <Icon path={mdiMagnify} size={'2rem'} />
                </button>
                <div className="user" onClick={this.openMenu}>

                    <div className="icon">
                        <Icon path={mdiAccount} size={'3rem'}/>
                    </div>

                    <div className="info">
                        <span className="branche_name">{ (branche ? branche.name : 'Unknown') }</span>
                        <span className="name">{ name }</span>
                    </div> 
                </div> 
                {
                    user_menu
                    &&
                    <div className="user_menu_background" onClick={this.closeMenu}>
                        <div className="user_menu">
                            <div className="item-list">
                                <ul>
                                    <li><button onClick={this.toggleFullscreen}><Icon path={(!document.fullscreenElement ? mdiFullscreen : mdiFullscreenExit)} size={'2rem'}/> {(!document.fullscreenElement ? 'Fullscreen' : 'Exit fullscreen')}</button></li>
                                    <li><button onClick={this.logout}><Icon path={mdiLogoutVariant} size={'2rem'}/> Uitloggen</button></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }

	render() {
        const { authenticated, onBack } = this.props;

		return (
            <div className="header">
                <div className="left">
                    {
                        onBack
                        ?
                            <button className="icon back" onClick={onBack}>
                                <Icon path={mdiArrowLeft} size={'2rem'} />
                            </button>
                        :
                            null
                    }
                    <Link to="/groothandels">
                        <img className="logo" src="/images/logo-white.png" alt="Hanos" />
                    </Link>
                </div>
                <div className="middle"></div>
                <div className="right">
                    { authenticated && this.renderUser() }
                </div>
            </div>
		);
	}
}

Header.defaultProps = {
    onBack: null
};


function mapStateToProps(state) {	
    const { user: { authenticated, user } } = state;

	return {
        authenticated: authenticated,
        user: user,
	};
}

export default connect(mapStateToProps)(withRouter(Header));