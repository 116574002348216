import Dashboard from '../../../lib/api/Dashboard';

export function routesRequest() {
	return {
		type: 'ROUTES_REQUEST'
	};
}

export function routesSuccess(data) {

	return {
		type: 'ROUTES_SUCCESS',
		data: data,
		lastUpdate: Date.now()
	};
}

export function routesError(error) {
	return {
		type: 'ROUTES_ERROR',
		error: error
	};
}


export function addRoute(data) {

	return {
		type: 'ROUTE_ADD',
		data: data
	};
}

export function updateRoute(data) {

	return {
		type: 'ROUTE_UPDATE',
		data: data
	};
}

export function updateActivity(route_id, data) {

	return {
		type: 'ROUTE_UPDATE_ACTIVITY',
		route_id: route_id,
		data: data
	};
}

/* Async actions */

export function getRoutes(params) {

	return function(dispatch, getState) {

		const state = getState();

		if (state.routes.all.loading) {
			return Promise.resolve();
		}
		
		dispatch(routesRequest());

		return Dashboard.getRoutes(params).then(
			data =>  dispatch(routesSuccess(data)),
			error => dispatch(routesError(error))
		);
	}
}
