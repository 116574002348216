import { bearing }          from '../../lib/crossyn/GPSUtils';


const initialState = {
    
    data: {},

    error: null,
    loading: false,
    lastUpdate: null
};

export default function vehicles(state = initialState, action) {

    switch (action.type) {

        case 'VEHICLE_REQUEST':

            return Object.assign({}, state, {
                error: null,
                loading: true
            });

        case 'VEHICLE_SUCCESS':
            var { lastUpdate } = action;
            var { data=[] } = action.data || {};

            var vehicles = {};
            
            data.forEach((vehicle) => {
                vehicles[vehicle.licenseplate] = vehicle;
            });

            return Object.assign({}, state, {
                data: vehicles,
                error: null,
                loading: false,
                lastUpdate: lastUpdate
            });

        case 'VEHICLE_ERROR':

            var { error } = action;

            return Object.assign({}, state, {
                error: error,
                loading: false
            });

        case 'VEHICLE_POSITION_UPDATE':
            var { licenseplate, data } = action;

            var vehicles = {
                ...state.data,
                [licenseplate]: {
                    ...state.data[licenseplate],
                    ...data
                }
            };

            return Object.assign({}, state, {
                data: vehicles
            });

        default:
            return state;
        
    }
    
}
