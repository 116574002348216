import React, { Component } from 'react';

import { connect }          from 'react-redux';

import { getRoutes }        from '../../../redux/routes/list/actions';
import { getBranches }      from '../../../redux/branches/list/actions';

import Page                 from '../../../components/page/Page';
import Map                  from '../../../components/maps/Map';
import RouteLayer           from '../../../components/maps/layers/RouteLayer';
import BranchesLayer        from '../../../components/maps/layers/BranchesLayer';
import ErrorState           from '../../../components/state/ErrorState';
import LoadingState         from '../../../components/state/LoadingState';

import Item                 from './item/Item';

class List extends Component {
    
    componentDidMount() {
        
        const { dispatch } = this.props;
        
        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

        dispatch(getBranches()).then((action) => {
            
            const { error } = action;

            const { data=[] } = action.data || {};

            if (data) {
                const branches = data.map((branche) => { return branche.number });

                dispatch(getRoutes({
                    branch_nummer: { $in: branches },
                    createdAt: { $gte: today.getTime() }
                    //_limit: 10000
                }));
            }
        });
    }

    renderList() {

        const { list, match: { url }, route_loading } = this.props;
        
        const branche_list = list.map((branche, index) => {
            return (
                <li key={branche}>
                    <Item id={branche} url={url} />
                </li>
            );
        });

        if (route_loading) { 
            return (
                <div className="card loading" style={ {flex:1} }>
                    <div>Bezig met laden...</div>
                </div>
            );
        }

        return (
            <div className="card scroll-y" style={ {flex:1} }>
                <div className="item-list">
                    <ul>
                        { branche_list }
                    </ul>
                </div>
            </div>
        );
    }

    renderRouteLayers() {

        const { route_list } = this.props;

        if (!route_list.length) { return null; }

        const route_layers = route_list.map((route_id) => {
            return (
                <RouteLayer
                    key={route_id}
                    route_id={route_id}
                    //route={route}
                />
            );
        });

        return route_layers;
    }

    renderBranchesLayer() {
        const { branches } = this.props;

        return <BranchesLayer branches={branches} />

    }

    renderMap() {

        return (
            <Map
                zoom={8}
                panX={-200}
                /*center={{
                    latitude: data.latitude,
                    longitude: data.longitude
                }}*/
            >
                
                { this.renderRouteLayers() }

                { this.renderBranchesLayer() }

            </Map>
        );
    }

    render() {

        const { error, loading , branches } = this.props;

        if (loading) { return <LoadingState label="Bezig met laden..." />; }
        if (error) { return <ErrorState label="Er is een fout opgetreden" message={error.message} />; }

        return (
            <Page>

                <div style={ {position: 'relative', height:'100%'} }>

                    <div className="sidebar">
                        
                        <div className="card">
                            <h2 style={ {margin:0, padding:20} }>Groothandels</h2>
                        </div>

                        { this.renderList() }

                    </div>

                    { this.renderMap() }

                </div>

            </Page>
        );
    }
}

function mapStateToProps(state, props) {
    const { list: route_list, loading: route_loading } = state.routes.all;
    const { list, error, loading, data: branches } = state.branches.all;

	return {
        list: list,
        error: error,
        loading: loading,
        route_list: route_list,
        branches: branches,
        route_loading: route_loading
	};
}

export default connect(mapStateToProps)(List);
