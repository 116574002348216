import React, { Component } from 'react';


class MapProvider extends Component {

    render() {
        
        const { children, map } = this.props;
        
        return (
            <MapContext.Provider value={{map: map}}>

                { children }
           
            </MapContext.Provider>
        );
    }

}

const MapContext = React.createContext({});

export { MapContext, MapProvider };