import React, { Component } from 'react';
import ReactDOM             from "react-dom"

import { MapContext }       from './MapProvider';


class Marker extends Component {

    constructor(props, context) {
        super(props, context);        

        this.marker = null;
        this.listener = null;

        this.default_icon = {
            path: 'M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z',
            fillColor: '#ea4335',
            fillOpacity: 1,
            strokeColor: 'rgba(255, 255, 255, 0.6)',
            strokeWeight: 1,
            scale: 2,
            anchor: {x: 12, y: 24}, // 24x24
            labelOrigin: {x: 12, y: 16}
        };

        this.containerElement = document.createElement('div');

        this.state = {
            visible: true
        }

    }

    componentDidMount() {
        const { icon: custom_icon, position, rotation, infoWindowContent, label, label_color='#343434', hideZoomLevel=null } = this.props;
        const { map } = this.context;
        const { visible } = this.state;

        let icon = null;
        if (typeof custom_icon === 'object') {
   
            if (custom_icon.url !== undefined) {
                icon = custom_icon;
            } else {
                icon = {
                    ...this.default_icon,
                    ...custom_icon
                };
            }

            icon.rotation = rotation;
        } else {
            icon = custom_icon;
        }
        
        let marke_label = null;

        if (label) {
            marke_label = {
                text: String(label),
                fontSize: '11px',
                color: label_color
            }
        }
        
        this.marker = new window.google.maps.Marker({
            map: this.context.map,
            position: {
                lat: position.latitude,
                lng: position.longitude
            },
            icon: icon,
            label: marke_label,
            visible: visible
        });

        if (infoWindowContent !== null) {
            this.infoWindow = new window.google.maps.InfoWindow();
            this.infoWindow.setContent(this.containerElement);
        }

        this.listener = this.marker.addListener('click', this.onMarkerClick);

        if (hideZoomLevel !== null) {

            this.setState({visible: map.getZoom() > hideZoomLevel});

            this.zoom_listener = map.addListener("zoom_changed", () => {
                this.setState({visible: map.getZoom() > hideZoomLevel})
            });
        }
    }

    componentWillUnmount() {
        window.google.maps.event.removeListener(this.listener);

        if (this.zoom_listener) {
            window.google.maps.event.removeListener(this.zoom_listener);
        }
        
        this.marker.setMap(null);
    }

    onMarkerClick = (event) => {
        const { onClick, infoWindowContent } = this.props;

        if (onClick) {
            onClick();
        }
       
        if (infoWindowContent !== null) {
            this.infoWindow.open(this.context.map, this.marker);
        }
        
    }

    focus = () => {
        const { map } = this.context;
        const { position: { latitude, longitude } } = this.props;

        map.setCenter({
            lat: latitude,
            lng: longitude
        });

        if (map.getZoom() < 15) {
            map.setZoom(15);
        }
        
        this.marker.setAnimation(window.google.maps.Animation.BOUNCE);

        let timout = setTimeout(() => {
            this.marker.setAnimation(null);
        }, 5000)
        
    }

    componentDidUpdate(prevProps, prevState) {
        const { position, rotation } = this.props;
        const { visible } = this.state;
        
        if (rotation !== prevProps.rotation) {
            const icon = this.marker.getIcon();
            if (icon) {
                icon.rotation = (rotation ? rotation : 0);
                this.marker.setIcon(icon);
            }
        }

        if (this.props.icon.fillColor && prevProps.icon.fillColor && this.props.icon.fillColor !== prevProps.icon.fillColor) {
            const icon = this.marker.getIcon();
            if (icon) {
                icon.fillColor = this.props.icon.fillColor;
                this.marker.setIcon(icon);
            }
        }

        if (position !== prevProps.position && position.latitude !== null && position.longitude !== null ) {
            this.marker.setPosition({
                lat: position.latitude,
                lng: position.longitude
            });
        }

        if (prevState.visible !== visible) {
            this.marker.setVisible(visible);
        }

    }

    render() {
        const { infoWindowContent } = this.props;

        if (infoWindowContent !== null) {
            return ReactDOM.createPortal(
                infoWindowContent,
                this.containerElement
            );
    
        }

        return null;
    }
}

Marker.contextType = MapContext;

Marker.defaultProps = {
    icon: {},
    position: {
        latitude: 0,
        longitude: 0
    },
    rotation: 0,
    onClick: () => {},
    infoWindowContent: null,
    hideZoomLevel: null
};

export default Marker;
