import React, { Component } from 'react';

import { connect }          from 'react-redux';

import { withRouter }       from 'react-router-dom';

import Item                 from './Item';


class RouteInfo extends Component {
    
    renderRoutes() {

        const { list, branche_id } = this.props;

        if (!list.length) { return null; }
        
        const route_list = list.map((route_id) => {
            return (
                <li key={route_id}>
                    <Item
                        route_id={route_id}
                        url={'/groothandels/' + branche_id + '/routes/' + route_id}
                    />
                </li>
            );
        });

        return (
            <div className="item-list">
                <ul>
                    { route_list }
                </ul>
            </div>
        );
    }

    render() {

        const { error, loading } = this.props;
        
        if (loading) { 
            return (
                <div className="card loading" style={ {flex:1} }>
                    <div>Bezig met laden...</div>
                </div>
            );
        }

        if (error) { return <div>Error! {error.message}</div>; }

        return (
            <div className="card scroll-y" style={ {flex:1} }>
                { this.renderRoutes() }
            </div>
        );
    }
}

function mapStateToProps(state, props) {

    const { list, error, loading } = state.routes.all;

	return {
        list: list,
        error: error,
        loading: loading
	};
}

export default connect(mapStateToProps)(withRouter(RouteInfo));
