import React, { Component } from 'react';

import { connect }          from 'react-redux';

import Truck                from '../../../../components/maps/markers/Truck';

import { 
    msToTime,
    dateFormat
}                           from '../../../../lib/utils/Utils';
import { convertLicenseplate } from '../../../../lib/utils/Utils';


class Vehicle extends Component {
    
    render() {
        const { latitude, longitude, rotation, positionUpdated, duration=0, type, gpsgate_id } = this.props;

        if (!latitude || !longitude) { return null; }

        return (
            <Truck
                position={{
                    latitude: latitude,
                    longitude: longitude
                }}
                rotation={(rotation ? rotation : 0)}
                infoWindowContent={
                    <div className='infowindow'>
                        <b>Locatie</b><br/><br/>
                        Tijd: { dateFormat(new Date(positionUpdated), true) } <br/>
                        Stil: { msToTime(duration) } <br/>
                        Gpsgate id: { gpsgate_id }
                    </div>
                }
                type={type}
            />
        );
    }

}

function mapStateToProps(state, props) {
    const vehicle = state.vehicles.data[convertLicenseplate(props.licenseplate)];
	return vehicle;
}

export default connect(mapStateToProps)(Vehicle);
