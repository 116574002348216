import {
	createStore,
	combineReducers,
	applyMiddleware
}								from 'redux';

import { 
	persistStore,
	persistCombineReducers 
}                               from 'redux-persist';

import storage 					from 'redux-persist/lib/storage'

import thunkMiddleware 			from 'redux-thunk';

import app 						from './application/reducer';
import branches 				from './branches/reducer';
import routes 					from './routes/reducer';
import user						from './user/reducer';
import crossyn					from './crossyn/reducer';
import vehicles					from './vehicles/reducer';

const reducers = persistCombineReducers({
	key: 'hanos-dashboard',
	storage: storage,
	whitelist: ['user']
}, {
	app,
	user,
	branches,
	routes,
	//crossyn,
	vehicles
});

export default function configureStore() {

	const store = createStore(
		reducers,
		applyMiddleware(thunkMiddleware)
	);

	const persistor = persistStore(store);

	return { store, persistor };

}
