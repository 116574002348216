import React, { Component } from 'react';

import Dashboard from '../../lib/api/Dashboard';

import Page from '../../components/page/Page';
import Activityindicator from '../../components/activityindicator/Activityindicator';

import { 
    dateFormat
} from '../../lib/utils/Utils';

import './Search.css';


class Search extends Component {

    constructor(props) {
        super(props);

        const { search } = props.location;
        const params = new URLSearchParams(search);

        let type = 'customer';
        let query = '';

        if (params.get('type')) {
            type = params.get('type');
        }

        if (params.get('q')) {
            query = params.get('q');
        }

        if (params.get('customer')) {
            type = 'customer';
            query = params.get('customer');
        }

        this.state = {
            loading: false,
            type: type,
            query: query,
            results: null
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {

        const { type, query } = this.state;

        if (!query) { return; }
        
        this.setState({
            loading: true,
            results: null
        });

        window.history.pushState('', '', `?type=${type}&q=${query}`);

        Dashboard.search(type, query).then((data) => {
            this.setState({
                loading: false,
                results: data
            });

        }).catch((error) => {
            this.setState({
                loading: false,
                results: null
            });
            console.log(error);
        });
    }

    onSubmit = (event) => {

        this.loadData();
        
        event.preventDefault();
    }

    onChange = (event) => {
        
        const { name, value } = event.target;

        if (name === 'type') {
            this.setState({
                type: value,
                results: null
            });
        } else {
            this.setState({
                [name]: value
            });
        }
    }

    renderList(routes) {
        const { type, query } = this.state;
 
        routes.sort((a,b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
        });

        return (
            <ul>
                { 
                    routes.map((route, index) => {

                        let customerActivity = null
                        if (type === 'customer') {
                            customerActivity = route.activities.find((activity) => {
                                return activity.customer_name.includes(query) || activity.customer_second_name.includes(query) || activity.customer_number == query;
                            });
                        }
                    
                        let brancheId = route.branche.documentId;
             
                        return (
                            <li key={String('result'+index)}>
                                <a href={'/groothandels/' + brancheId + '/routes/' + route.documentId}>
            
                                    <div className="item-header">
                                        <div style={ {fontSize:18} }>Route {route.number}</div>
                                        <div style={ {fontSize:16} }>{ dateFormat(new Date(route.createdAt), false)}</div>
                                    </div>

                                    { type === 'licenseplate' || type === 'driver'  && <div style={ {color:'#999'} }>{ route?.licenseplate } - { route?.driver_name } ({ route?.number })</div> }
                                    { (type === 'customer' && customerActivity) && <div style={ {color:'#999'} }>{ customerActivity.customer_name } { customerActivity.customer_second_name } ({ customerActivity.customer_number })</div> }
                               
                                </a>
                            </li>
                        )
                    })
                }
            </ul>
        )
    }

    renderResult() {
        const { results } = this.state;

        if (results === null) { return null; }

        const sectionsObject = results.reduce((branches, route) => {

            const branche = (Array.isArray(route.branche) ? route.branche[0] : route.branche);
            const id = branche.documentId
   
            if (!branches[id]) {
                branches[id] = {
                    id: id,
                    name: branche.name,
                    routes: []
                }
            }

            branches[id].routes.push(route);

            return branches;
        }, {});

        const branches = Object.values(sectionsObject);

        branches.sort((a,b) => {
            return a.name.localeCompare(b.name);
        });

        results.sort((a,b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
        });

        return (
            <div className="card">
                <h2 style={ {margin:0, padding:20} }>{ results.length } routes gevonden</h2>
                <div className="item-list">

                    {
                        branches.map(({ name, routes }) => {
                            return (
                                <>
                                    <div className="branche">{ name }</div>
                                    <ul>
                                        { this.renderList(routes) }
                                    </ul>
                                </>
                            )
                           
                        })
                    }

                </div>
            </div>
        );
    }

	render() {
        const { type, query, loading } = this.state;

        let placeholder = 'Klantnaam of nummer';
        if (type === 'driver') {
            placeholder = 'Chaffeursnaam of nummer';
        } else if (type === 'licenseplate') {
            placeholder = 'Kenteken';
        }

		return (
            <Page>
                <div className="container-centered">
                    
                    <div className="card">
                        <h2 style={ {margin:0, padding:20} }>Zoeken</h2>
                        <form className="search-form" onSubmit={this.onSubmit}>
                            <div className="field" style={ {alignItems:'center'} }>
                                <input type="radio" name="type" id="customer" value="customer" onChange={this.onChange} defaultChecked />
                                <label htmlFor="customer">Klant</label>
                            </div>
                            <div className="field" style={ {alignItems:'center'} }>
                                <input type="radio" name="type" id="driver" value="driver" onChange={this.onChange} />
                                <label htmlFor="driver">Chauffeur</label>
                            </div>
                            <div className="field" style={ {alignItems:'center'} }>
                                <input type="radio" name="type" id="licenseplate" value="licenseplate" onChange={this.onChange} />
                                <label htmlFor="licenseplate">Kenteken</label>
                            </div>
                            <div className="field">
                                <input type="text" name="query" value={query} placeholder={placeholder} minLength="3" onChange={this.onChange} required />
                                <button type="submit" disabled={loading}>Zoeken</button>
                            </div>
                        </form> 
                    </div>

                    { loading && <div className="loading"><Activityindicator /></div> }

                    { this.renderResult() }

                </div>
            </Page>
		);
	}
}

export default Search;