import React, { PureComponent } from 'react';

import { MapContext }       from './MapProvider';

class Line extends PureComponent {

    constructor(props, context) {
        super(props, context);   
        
        this.line = null;
    }

    componentDidMount() {
        const { path, strokeColor, strokeOpacity, strokeWeight } = this.props;

        this.line = new window.google.maps.Polyline({
            map: this.context.map,
            path: path,
            geodesic: true,
            strokeColor: strokeColor,
            strokeOpacity: strokeOpacity,
            strokeWeight: strokeWeight,
        });
    }

    componentWillUnmount() {
        this.line.setMap(null);
    }

    render() {
        return null;
    }
}

Line.contextType = MapContext;

Line.defaultProps = {
    strokeColor: '#FF0000',
    strokeOpacity: 1,
    strokeWeight: 0,
};

export default Line;
