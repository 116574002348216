import React, { Component } from 'react';

import { connect }          from 'react-redux';

import Page                 from '../../../components/page/Page';


class List extends Component {
    
    render() {

        return (
            <Page>list</Page>
        );
    }
}

export default List;
