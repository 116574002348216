import React, { PureComponent } from 'react';

import { MapContext }       from './MapProvider';

class Polyline extends PureComponent {

    constructor(props, context) {
        super(props, context);   
        
        this.polyline = null;

        this.drawLines();
        
    }

    componentDidUpdate() {
        this.drawLines();
    }

    componentWillUnmount() {
        this.polyline.setMap(null);
    }

    drawLines = () => {
        const { path, strokeColor, strokeOpacity, strokeWeight, icons=null } = this.props;

        if (this.polyline) {
            this.polyline.setMap(null);
        }

        this.polyline = new window.google.maps.Polyline({
            path: path,
            geodesic: true,
            strokeColor: strokeColor,
            strokeOpacity: strokeOpacity,
            strokeWeight: strokeWeight,
            icons: icons
        });
        this.polyline.setMap(this.context.map);
    }

    render() {
        return null;
    }

}

Polyline.contextType = MapContext;

Polyline.defaultProps = {
    path: [],
    strokeColor: '#57004e',
    strokeOpacity: 1,
    strokeWeight: 2
};

export default Polyline;
