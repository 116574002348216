
const initialState = {
    authenticated: false,

    jwt: '',
    user: null,

    error: null,
    loading: false,

    lastUpdate: null

};

export default function user(state = initialState, action) {

    switch (action.type) {

        case 'USER_REQUEST':

            return Object.assign({}, state, {
                error: null,
                loading: true
            });

        case 'USER_SUCCESS':
            var { jwt, user, lastUpdate } = action;

            return Object.assign({}, state, {
                authenticated: true,

                jwt: jwt,
                user: user,

                error: null,
                loading: false,
                lastUpdate: lastUpdate
            });

        case 'USER_ERROR':
            var { error } = action;

            return Object.assign({}, state, {
                error: error,
                loading: false
            });

        case 'LOGOUT':
            return initialState;
        default:
            return state;
        
    }
    
}
