import { combineReducers }  from 'redux';

import all					from './list/reducer';
import current				from './details/reducer';


const reducers = combineReducers({
	all,
	current
});

export default reducers;