import React, { Component } from 'react';

import Page               from '../../components/page/Page';


class notFound extends Component {

	render() {
		return (
            <Page>
                <div style={ {maxWidth: 768, margin: '0 auto', padding: 15} }>
                    
                    <div className="card" style={ {padding:20} }>
                        <h2>Helaas!</h2>
                        <p>De door jou opgevraagde pagina is niet gevonden.<br/>Klik <a href="/groothandels">hier</a> om naar het groothandel overzicht te gaan.</p>
                    </div>

                </div>
            </Page>
		);
	}
}

export default notFound;