import React, { useEffect, useState } from 'react';

import Dashboard from '../../../../lib/api/Dashboard';

import './Behaviour.css';

const Behaviour = ({ driver=null, licenseplate=null, date=null, branche=null }) => {

    const [ behaviour, setBehaviour ] = useState({
        overallrate: null,
        speedingrate: null,
        accelerationrate: null,
        decelerationrate: null
    });

    const [ show, setShow ] = useState(false);
    const [ error, setError ] = useState(null);

    useEffect(() => {

        const loadData = async () => {

            try {
                let startdate = new Date(date)
                startdate = startdate.getFullYear() + ("0"+(startdate.getMonth()+1)).slice(-2) + ("0" + startdate.getDate()).slice(-2);

                const response = await Dashboard.getBehaviour({ 
                    driverId: driver, 
                    driverHanosStoreNumber: branche, 
                    licensePlate: licenseplate,  
                    startdate: startdate
                });

                const { success=false, message=null, data={}} = response ?? {}
                if (success) {
                    const { overallrate=null, speedingrate=null, accelerationrate=null, decelerationrate=null } = data?.getBehaviourResponse ?? {}
                    setBehaviour({
                        overallrate: overallrate,
                        speedingrate: speedingrate,
                        accelerationrate: accelerationrate,
                        decelerationrate: decelerationrate
                    });
             
                } else {
                    setError(message);
                }
            
            } catch (error) {
           
            }
        }

        if (driver && licenseplate && date && branche) {
            loadData();
        }
        
    }, [driver, licenseplate, date, branche])

    return (
        <div className="behaviour">
            {
                show
                &&
                <>
                    {
                        error !== null
                        ?
                            <div className="line">
                                <div className="label">{ error }</div>
                            </div>
                        :
                        <>
                            <div className="line">
                                <div className="title">Cijfers</div>
                            </div>
                            <div className="line">
                                <div className="label">Totaal: </div>
                                <div className="value">{ behaviour.overallrate }</div>
                            </div>
                            <div className="line">
                                <div className="label">Snelheid: </div>
                                <div className="value">{ behaviour.speedingrate }</div>
                            </div>
                            <div className="line">
                                <div className="label">Optrekken: </div>
                                <div className="value">{ behaviour.accelerationrate }</div>
                            </div>  
                            <div className="line">
                                <div className="label">Afremmen: </div>
                                <div className="value">{ behaviour.decelerationrate }</div>
                            </div>
                         
                        </>
                    }
                    
                    <div className="line">
                        <button className='hide' onClick={() => { setShow(false) }}>Verberg rijgedrag</button>
                    </div>

                </>
            }

            {
                !show
                &&
                <button className='show' onClick={() => { setShow(true) }}>Bekijk rijgedrag</button>
            }

        </div>
    )
}

export default Behaviour;